import {
  SignatureMetadata,
  signatureMetadataSchema,
} from '@@api/signaturesApi';
import { useHookForm } from '@@forms/hooks/useHookForm';
import * as UI from '@@ui';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { z } from 'zod';

// We append the signedAt field before emitted the callback
export const signatureFormInputSchema = signatureMetadataSchema.omit({
  signedAtDateTimeUtc: true,
});
export type SignatureFormInput = z.infer<typeof signatureFormInputSchema>;

export const SignatureFormGrid: React.FC<{}> = () => {
  return (
    <UI.FormGrid>
      <UI.FormField name="signatureImage" type="signature" />
      <UI.FormField name="signedByNameText" label="Printed Name" />
    </UI.FormGrid>
  );
};

export type SignatureFormCompleteCallback = (input: SignatureMetadata) => any;

export const useSignatureForm = (onComplete: SignatureFormCompleteCallback) => {
  const form = useHookForm<SignatureFormInput>({
    resolver: zodResolver(signatureFormInputSchema),
    onValid: async (data) => {
      await onComplete({
        ...data,
        signedAtDateTimeUtc: new Date(),
      });
    },
    // Each flow will handle non-validation messaging
    successToast: false,
    totalFailureToast: false,
  });

  return form;
};

export const SignatureForm: React.FC<{
  onComplete: SignatureFormCompleteCallback;
}> = ({ onComplete }) => {
  const form = useSignatureForm(onComplete);

  return (
    <UI.QuickForm form={form}>
      <SignatureFormGrid />
    </UI.QuickForm>
  );
};

export default SignatureForm;
